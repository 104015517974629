(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("clappr"), require("flvjs"));
	else if(typeof define === 'function' && define.amd)
		define(["clappr", "flvjs"], factory);
	else if(typeof exports === 'object')
		exports["FLVJSPlayback"] = factory(require("clappr"), require("flvjs"));
	else
		root["FLVJSPlayback"] = factory(root["Clappr"], root["flvjs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 